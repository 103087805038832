<template>
    <div class="webdesignwordpress-content">
        <v-container id="intro" class="cyan text-center white--text mb-4" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="6">
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">Webdesign mit WordPress – Webseite erstellen lassen</h1>
                    <h2 class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        Freelancer für professionelle Homepages mit Verstand &amp; Herz – Für lokale, kleine und mittelständische Unternehmen in Vorarlberg und ganz Österreich
                    </h2>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mb-16">
            <v-row>
                <v-col cols="12" sm="4">
                    <v-card class="h-100" color="orange" elevation="0" tile>
                        <v-img
                        alt="Eine Stadt auf der Erde in der Nacht aus Sicht der Raumstation ISS - Fotografin: NASA"
                        role="presentation"
                        :src="require('@/assets/images/mood/iss037e004654~large.jpg')"
                        height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-card class="patterned d-flex h-100" color="orange" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <p>
                                Du möchtest eine <strong>“erweiterte Visitenkarte” im Web für dein lokales,
                                    kleines oder mittelständisches Unternehmen</strong> mit einem Ladengeschäft oder einer Dienstleistung?
                            </p>
                            <p>
                                Deine Webseite soll professionell, ansprechend und funktional sein,
                                um <strong>mehr Kunden zu gewinnen und deinen Umsatz zu steigern?</strong>
                            </p>
                            <p>
                                Du wünschst dir dafür <strong>einen einzigen, kompetenten Ansprechpartner?</strong>
                            </p>
                            <p>
                                Dann bist du bei mir an der richtigen Adresse!
                                 Als erfahrene Freelancerin setze ich deine Website schnell und erfolgreich um – mit viel Know-how, Handwerk &amp; Leidenschaft.
                            </p>
                            <p>
                                Übrigens: Wusstest du schon,
                                 dass <strong>72 % der Verbraucher:innen</strong> eher Angebote oder Dienstleistungen <strong>von einem Unternehmen kaufen,
                                  das eine gut gestaltete und optimierte Webseite hat?</strong>
                                   (Quelle: <a href="https://www.linkedin.com/pulse/importance-good-website-acquiring-b2b-b2c-customers-szklarczyk" target="_blank">Studie Salesforce</a>).
                                    Eine eigene Unternehmens-Webseite zu haben, ist also definitiv eine lohnenswerte Investition!
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="patterned d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h2>
                                So profitierst du von einer professionellen Website &amp; der Zusammenarbeit mit mir:
                            </h2>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h3 class="mb-5">Warum überhaupt eine professionelle Website?</h3>
                            <ul>
                                <li>Weil sie <strong>Kompetenz, Glaubwürdigkeit &amp; Vertrauen</strong> vermittelt, das wichtigste Gut für dein Business.</li>
                                <li>Weil <strong>mehr Sichtbarkeit</strong> im Web auch <strong>mehr Neukunden &amp; Umsatz</strong> bedeutet – selbst für lokale Ladengeschäfte.</li>
                                <li>Weil du mit einer guten Webseite deine <strong>Markenbekanntheit steigern und Kunden langfristig binden</strong> kannst.</li>
                                <li>Weil du mit einer <strong>mobilfreundlichen Webseite</strong> noch mehr Interessenten über Smartphone, Tablet & Co. erreichst und von deinem Angebot überzeugst!</li>
                                <li>Weil eine starke Webseite die Basis ist, wenn du <strong>erfolgreiches Online-Marketing & Social-Media</strong> machen möchtest.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h3 class="mb-5">Warum mit mir als Profi-Webdesignerin arbeiten?</h3>
                            <ul>
                                <li>Weil ich <strong>Expertin in Webdesign &amp; WordPress</strong> bin und weiß, wie eine gute Webpräsenz funktioniert.</li>
                                <li>Weil ich <strong>moderne, gut designte, funktionale, einfach bedienbare und technisch stabile Webseiten</strong> baue.</li>
                                <li>Weil du bei mir <strong>alles aus einer Hand</strong> bekommst, über Partner auch Social-Media, SEO, Fotografie, Grafik & mehr.</li>
                                <li>Weil du mit mir <strong>1 Ansprechpartnerin</strong> hast, die immer mitdenkt, statt wechselnde Kontaktpersonen in einer großen Webagentur, denen du alles immer neu erzählen musst.</li>
                                <li>Weil die Dinge bei mir <strong>unkompliziert, schnell und zuverlässig</strong> laufen – sympathisch, persönlich & auf Augenhöhe.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="8">
                    <v-card class="patterned d-flex h-100 align-center" color="cyan darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-center text-large pa-10">
                            <h6 class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'">
                                Mache deine Unternehmung im Web sichtbar – unabhängig davon, ob du aus Bregenz,
                                 Feldkirch oder einem anderen Ort in Österreich kommst.
                                  Mit einer professionellen WordPress-Webseite,
                                   die sowohl dich als auch dein Unternehmen ins rechte Licht rückt,
                                    gelingt das im Handumdrehen. Ich setze dies gerne erfolgreich für dich um.
                            </h6>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-card class="h-100" color="cyan darken-1" elevation="0" tile>
                        <v-img
                        alt="Ein Bildschirm mit Codezeilen - Fotograf: Florian Olivo"
                        role="presentation"
                        :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1"
                        :src="require('@/assets/images/mood/florian-olivo-4hbJ-eymZ1o-unsplash.jpg')"
                        height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="10">
                    <v-card class="patterned" color="primary" elevation="0" tile>
                        <v-card-text class="text-center white--text pa-10">
                            <h3 class="my-5" :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">Neue Webseite jetzt anfragen!</h3>
                            <p class="title font-family-body font-weight-regular">
                                <span class="font-weight-bold">Schreibe mir eine E-Mail:</span> hallo@die-webmacherin.at<br/>
                                <span class="font-weight-bold">Oder rufe mich direkt an:</span> +43 (0)677 61 3344 87
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mb-16">
            <v-row justify="center">
                <v-col cols="12" lg="9">
                    <h3 class="text-center mb-5" :class="$vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'">Meine Kund:innen sind begeistert</h3>
                    <v-carousel hide-delimiters>
                        <v-carousel-item>
                            <v-sheet
                                class="patterned pa-16"
                                color="secondary"
                                height="100%"
                                tile
                            >
                                <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                                >
                                    <v-col class="text-center text-large white--text" cols="12">
                                        <p>
                                            Simply the best. Forget the rest.
                                        </p>
                                        <p class="font-italic body-1">Christina Wagner, wagnercommunications</p>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet
                                class="patterned pa-16"
                                color="secondary"
                                height="100%"
                                tile
                            >
                                <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                                >
                                    <v-col class="text-center text-large white--text" cols="12">
                                        <p>
                                            Mit Sara zusammenzuarbeiten ist für mich hervorragend gewesen. Ich bin mit dem Wunsch eines Website-Facelifts zu Sara gekommen. Das Ergebnis macht mich sehr glücklich, und die Zusammenarbeit war auf den Punkt. Sara hat es verstanden, eine sehr gute Balance aus meinen Wünschen und ihren Vorschlägen umzusetzen. Die Kommunikation ist stets auf angenehme Weise lösungs- und sachorientiert. Die Umsetzung stets zügig, die Arbeitsweise transparent. Ich erlebte Sara als höchst kompetent. Sara, ich danke Dir auf diesem Wege nochmals für unsere hervorragende Zusammenarbeit. Du bist eine absolute Empfehlung.
                                        </p>
                                        <p class="font-italic body-1">Robert Raithel, Robert Raithel Fotografie</p>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-sheet
                                class="patterned pa-16"
                                color="secondary"
                                height="100%"
                                tile
                            >
                                <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                                >
                                    <v-col class="text-center text-large white--text" cols="12">
                                        <p>
                                            "Webseiten mit Verstand und Herz!" ... das wäre ein Slogan, den ich Sara gerne attestieren möchte. Wer Webseiten möchte, die professionell wirken, die richtigen Funktionen am rechten Platz haben, nicht überfrachtet, sondern mit viel Liebe zum Detail gemacht sind - der kommt an Sara nicht vorbei!
                                        </p>
                                        <p>
                                            Anhand der von uns vertriebenen LändleKASSA können Webseiten automatisch mit Produkten mit allen Informationen befüllt werden: von Bildern über die Texte bis hin zum verwalteten aktuellen Lagerstand - und das über die lokale Warenwirtschaft - ohne Mehraufwand. Das ist innovativ und echt cool - aber! Die schönsten gepflegten Infos bringen nichts, wenn sie nicht richtig in Szene gesetzt werden, im richtigen Licht erstrahlen - und hier sind wir über die Zusammenarbeit mit Sara extrem dankbar - gemeinsam werden die Projekt zum PERFEKTEN ERFOLG!
                                        </p>
                                        <p>
                                            DANKE Sara für die sehr tolle Zusammenarbeit und dein unermüdliches Engagement!
                                        </p>
                                        <p class="font-italic body-1">Mike Fleisch, LändleKASSA</p>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mb-16">
            <v-row>
                <v-col class="text-center" cols="12">
                    <h3 :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'">Beispiele für WordPress Websites: Ob schlicht, klassisch oder ausgefallen – Ich bin deine Webdesignerin für alle Fälle!</h3>
                    <p class="text-large">
                        Mach dir gern selbst ein Bild meiner Expertise. Hier ist eine Auswahl von Websites, die ich mit WordPress erstellt habe:
                    </p>
                </v-col>
            </v-row>
            <v-row align="stretch">
                <v-col
                    v-for="(reference ,i) in references"
                    :key="i"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3"
                >
                    <info-card-vertical :contentProp="reference" />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" cols="12">
                    <v-btn
                        color="primary"
                        href="/referenzen"
                        x-large
                    >
                        Hier findest du alle weiteren Referenzen.
                        <v-icon dark right>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center">
                <v-col class="text-center" cols="12" lg="9">
                    <v-card class="patterned" color="orange" elevation="0" tile>
                        <v-card-text class="text-center white--text pa-10">
                            <h3 class="my-5" :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'">Du verfügst bereits über eine Webseite, spielst jedoch mit dem Gedanken, auf WordPress zu wechseln?</h3>
                            <p class="text-large">
                                Das ist kein Problem!
                                 Dank meiner Erfahrung als Webdesignerin bin ich nicht nur mit WordPress, sondern auch mit Typo3, HTML/CSS und weiteren Systemen bestens vertraut.
                                   Gerne übernehme ich für dich die Umstellung, Installation und Optimierung deiner Webseite auf Basis von WordPress.
                            </p>
                            <p class="text-large">
                                Selbst Pagebuilder und ähnliche Dienste wie Wix, Squarespace oder Shopify lassen sich in eine WordPress-Webseite transformieren.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row>
                <v-col cols="12" sm="8">
                    <v-card class="patterned d-flex h-100 align-center" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-center text-large pa-10">
                            <h2 class="mb-5" :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">
                                Ein WordPress Webdesign,<br>
                                das dir wirklich etwas nützt
                            </h2>
                            <p>
                                Beim Stichwort “Webdesign” denken viele sofort an Schönheitspreise.
                                 Natürlich ist es wichtig, dass deine Website ästhetisch ansprechend ist und deinen Stil widerspiegelt.
                                  Aber weißt du, was für deinen Erfolg noch wichtiger ist?
                            </p>
                            <p class="font-weight-bold">
                                Dass deine “Visitenkarte im Netz” technisch stabil funktioniert und in Sachen Usability & Performance überzeugt.
                                 Denn das ist die Basis, um mehr und passende Kunden anzuziehen, die in deinen Shop kommen, mit dir Kontakt aufnehmen oder dein Angebot direkt buchen.
                            </p>
                            <h3 :class="$vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'">
                                Und genau das bekommst du bei mir:<br>
                                Homepage umsetzen mit WordPress
                            </h3>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-card class="h-100" color="lime darken-1" elevation="0" tile>
                        <v-img
                            alt="Webseite-Design auf Papier - Fotograf: Hal Gatewood"
                            role="presentation"
                            :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1"
                            :src="require('@/assets/images/mood/hal-gatewood-tZc3vjPCk-Q-unsplash.jpg')"
                            height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h3 class="mb-5" :class="$vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'">Mein Angebot:</h3>
                            <h4 class="mb-5">Klassische WordPress Webseite für Unternehmer:innen – Modern, praktisch, überzeugend!</h4>
                            <p>
                                Du stehst mit deiner Idee noch ganz am Anfang? Dann fangen wir dort an:
                                 Mit dem Hosting und der Einrichtung deiner WP Webseite und E-Mail-Adresse sowie einem Google Business Unternehmensprofil.
                                  Schritt für Schritt bauen wir deine Webseite auf. Dazu gehört auch diese Struktur:
                            </p>
                            <ul>
                                <li><strong>Startseite:</strong> Das zentrale Schaufenster, in dem wir dein Business rundum wirkungsvoll präsentieren.</li>
                                <li><strong>Angebotsseite:</strong> Hier geht es um dein Sortiment, deine Dienstleistung oder Produkte sowie deren Mehrwert.</li>
                                <li><strong>Über uns-Seite:</strong> Zeige Gesicht & Persönlichkeit sowie die Geschichte hinter deinem Unternehmen. Denn Menschen möchten bei Menschen einkaufen.</li>
                                <li><strong>Kontaktseite:</strong> Mache es den Menschen leicht, mit dir in Verbindung zu treten, mit gut sichtbaren Optionen der Kontaktaufnahme (wie z.B. E-Mail, Telefon, Kontaktformular). </li>
                                <li>Natürlich dürfen auch <strong>Impressum / Datenschutz</strong> und <strong>Cookie Hinweis</strong> nicht fehlen.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h4 class="mb-5">Darf es noch ein wenig mehr sein?</h4>
                            <p>
                                Wenn du deine WP Webseite als Basis und Sprungbrett für weitere Online-Aktivitäten nutzen willst,
                                 kümmere ich mich gern um die <strong>Einrichtung von:</strong>
                            </p>
                            <ul class="mb-4">
                                <li><strong>Social-Media-Accounts</strong></li>
                                <li><strong>Newsletter-System</strong></li>
                                <li><strong>Blogs</strong></li>
                                <li><strong>Internem Bereich (Intranet)</strong></li>
                                <li>deinem eigenen <strong>Onlineshop mit WooCommerce</strong></li>
                            </ul>
                            <p>
                                und berate dich gern grundlegend zum Thema <strong>Online-Marketing</strong> – und welche Kanäle dir welchen Mehrwert bieten können.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card class="d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h4 class="mb-5">Besondere Designs, Fotos, optimierte Texte & mehr – Kein Problem mit meinem Partner-Netzwerk!</h4>
                            <p>
                                Dank meiner Zusammenarbeit mit erfahrenen Spezialisten aus unterschiedlichen Fachgebieten kann ich dich auch bei sehr speziellen Anforderungen unterstützen.
                                 So erhältst du bei mir stets alles aus einer Hand.
                                  Zu meinem Leistungsspektrum gehören neben der Berücksichtigung rechtlicher Aspekte der DSGVO insbesondere die folgenden Dienstleistungen:
                            </p>
                            <ul class="mb-4">
                                <li><strong>Fotografie / Videos:</strong> Weil (bewegte) Bilder dein Unternehmen erlebbar und greifbar machen.</li>
                                <li><strong>Individuelle Grafik- und Designwünsche:</strong> Weil du die Persönlichkeit und Einzigartigkeit deines Unternehmens damit besonders gut zum Ausdruck bringen kannst.</li>
                                <li><strong>Optimierung für Suchmaschinen / SEO-Texte:</strong> Damit du bei Google & Co. höher im Ranking stehst und damit noch mehr & passende Kunden anziehst.</li>
                                <li><strong>Social-Media-Marketing:</strong> Mit Facebook, Instagram, LinkedIn & Co. kannst du noch mehr Zielgruppen erreichen und durch die interaktive Kommunikation an deine Marke binden.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="10">
                    <v-card class="patterned" color="primary" elevation="0" tile>
                        <v-card-text class="text-center white--text pa-10">
                            <h3 class="my-5" :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">
                                Deine neue Webseite<br>
                                Machen wir dein Unternehmen online sichtbar!
                            </h3>
                            <p class="title font-family-body font-weight-regular">
                                <span class="font-weight-bold">Schreibe mir eine E-Mail:</span> hallo@die-webmacherin.at<br/>
                                <span class="font-weight-bold">Oder rufe mich direkt an:</span> +43 (0)677 61 3344 87
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        
        <v-container>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-card class="h-100" color="cyan darken-1" elevation="0" tile>
                        <v-img
                            alt="Wunderkerzen-Herz - Fotograf: Jamie Street"
                            role="presentation"
                            :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 1"
                            :src="require('@/assets/images/mood/jamie-street-hBzrr6m6-pc-unsplash.jpg')"
                            height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-card class="patterned d-flex h-100 align-center" color="cyan darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-center text-large pa-10">
                            <h3 class="mb-5" :class="$vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'">
                                “Webseiten mit Verstand & Herz” – Ich helfe dir dabei, deine Unternehmensziele zu erreichen!
                            </h3>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex h-100" color="cyan darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h4 class="mb-5">Was genau ist dein Ziel?</h4>
                            <p>
                                Willst du, dass mehr Kunden in dein Geschäft / Restaurant / Studio kommen? Oder möchtest du,
                                 dass sich mehr Menschen für eine Erstberatung bei dir melden? Wie auch immer:
                                  Deine Geschäftsziele und die Bedürfnisse deiner Kunden bestimmen auch mein Vorgehen.
                                   Denn deine Website soll dir wirklich etwas bringen.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex h-100" color="cyan darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h4 class="mb-5">Ich helfe dir dabei</h4>
                            <ul class="mb-4">
                                <li>eine zuverlässige, stabile und technisch einwandfreie WP Webseite</li>
                                <li>mit ansprechendem und gern auch einzigartigen Design aufzubauen,</li>
                                <li>die leicht bedienbar ist für dich und deine Mitarbeiter:innen,</li>
                            </ul>
                            <h4 class="mb-5">um damit</h4>
                            <ul class="mb-4">
                                <li>mehr Kunden zu gewinnen,</li>
                                <li>eine echte “Marke” zu werden</li>
                                <li>und eine starke Onlinepräsenz aufzubauen.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card class="d-flex h-100" color="cyan darken-1" elevation="0" tile>
                        <v-card-text class="white--text text-large pa-10">
                            <h4 class="mb-5">“Webseiten mit Verstand & Herz”</h4>
                            <p>
                                So bringen Kund:innen meine Tätigkeit auf den Punkt.
                                 Und genau das tue ich: Ich fuchse mich schnell in dein Projekt ein und setze meine fachlichen Kenntnisse,
                                  meine langjährige Erfahrung, mein geschultes Auge und meine Liebe zum Detail dafür ein,
                                   dass deine neue Website den Mehrwert und die Persönlichkeit deines Unternehmens widerspiegelt
                                    und ganz auf die Bedürfnisse deiner Zielgruppe zugeschnitten ist.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="10">
                    <v-card class="patterned" color="primary" elevation="0" tile>
                        <v-card-text class="text-center white--text pa-10">
                            <h3 class="my-5" :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">
                                Als Webdesignerin bin ich für dich da!
                            </h3>
                            <p class="title font-family-body font-weight-regular">
                                <span class="font-weight-bold">Schreibe mir eine E-Mail:</span> hallo@die-webmacherin.at<br/>
                                <span class="font-weight-bold">Oder rufe mich direkt an:</span> +43 (0)677 61 3344 87
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center">
                <v-col cols="12">
                    <v-card class="patterned" color="indigo darken-1" elevation="0" tile>
                        <v-card-text class="text-center white--text pa-10">
                            <h2 class="my-5" :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">
                                Zum Ablauf: In nur 7 Schritten deine neue WordPress Website realisieren lassen
                            </h2>
                            <p class="title font-family-body font-weight-regular">
                                Du möchtest eine WordPress-Seite erstellen lassen? Nichts leichter als das.
                                 In nur 7 Schritten gehst du mit deiner neuen Webseite an den Markt:
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="h-100" color="cyan darken-1" elevation="0" tile>
                        <v-img
                            alt="Plan auf Schiefertafel - Fotograf: Volodymyr Hryshchenko"
                            role="presentation"
                            :aspect-ratio="$vuetify.breakpoint.sm ? 2/1 : 1"
                            :src="require('@/assets/images/mood/volodymyr-hryshchenko-x_OKGbxB3fA-unsplash.webp')"
                            height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="8">
                    <v-stepper id="schritt1" class="indigo darken-1 text-center text-large white--text" alt-labels vertical v-model="websitesteps">
                        <v-stepper-step step="1" @click="websitesteps = 1">
                            <h4 class="text-center white--text">Kostenloses Erstgespräch</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="1">
                            <p>
                                Hier besprechen wir, was du dir für deinen Internetauftritt wünschst und welche Ideen du hast –
                                 angefangen von den notwendigen Funktionen, Einstellungen und der grundlegenden Struktur bis hin zu deinen Design-Vorstellungen,
                                  natürlich im Corporate Design deines Unternehmens, soweit vorhanden.
                                   Bei diesem Gespräch klären wir auch, ob du spezielle Anforderungen benötigst
                                    oder was du eventuell auch selbst übernehmen kannst (z.B. Texte & Bilder organisieren).
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 2"
                            >
                                Weiter
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="2" @click="websitesteps = 2">
                            <h4 class="text-center white--text">Angebot erstellen</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="2">
                            <p>
                                Auf dieser Basis erstelle ich ein verbindliches Angebot. Bist du damit einverstanden, kann es direkt losgehen.
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 1"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 3"
                            >
                                Weiter
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="3" @click="websitesteps = 3">
                            <h4 class="text-center white--text">Technisches Set-up</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="3">
                            <p>
                                Zunächst wird die Basis geschaffen: das Hosting (sofern bis jetzt nicht vorhanden),
                                 eine erste grundlegende Website mit deinen Kontaktdaten,
                                  die Einrichtung deiner E-Mail-Adresse sowie dein Google Unternehmensprofil.
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 2"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 4"
                            >
                                Weiter
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="4" @click="websitesteps = 4">
                            <h4 class="text-center white--text">Briefing für weitere Inhalte und Maßnahmen</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="4">
                            <p>
                                Gemeinsam besprechen wir, welche weiteren Seiten und inhaltlichen Elemente deine Online-Präsenz umfassen soll.
                                 Dabei gibt es grundsätzlich 2 Möglichkeiten: Entweder du hast bereits bestehende Texte, Bilder, Videos, Grafiken etc.
                                  und lässt mir diese zukommen. Oder du möchtest neue Inhalte, etwa für Suchmaschinen optimierte Texte, erstellen lassen.
                                   In Kooperation mit erfahrenen Expert:innen meines langjährigen und bewährten Partner-Netzwerkes kann ich auch diese Leistungen und Maßnahmen auf Wunsch übernehmen.
                                    Ich bleibe dabei deine feste Ansprechpartnerin und koordiniere den gesamten Prozess. 
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 3"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 5"
                            >
                                Weiter
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="5" @click="websitesteps = 5">
                            <h4 class="text-center white--text">Einbau der weiteren Elemente</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="5">
                            <p>
                                Schließlich baue ich die einzelnen Texte, Fotos & Co. gemäß deinen Wünschen auf deine Webseite ein.
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 4"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 6"
                            >
                                Weiter
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="6" @click="websitesteps = 6">
                            <h4 class="text-center white--text">Last Check</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="6">
                            <p>
                                Es erfolgt eine letzte technische Prüfung, ob deine WP-Webseite rundum einwandfrei funktioniert.
                                 Navigationselemente, Ladezeiten, Content-Elemente – Läuft alles stabil und wird korrekt angezeigt?
                                  Wenn ja, dann gibt es grünes Licht!
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 5"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 7"
                            >
                                Weiter
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="7" @click="websitesteps = 7">
                            <h4 class="text-center white--text">Und GO: Deine neue Webseite geht online!</h4>
                        </v-stepper-step>
                        <v-stepper-content class="ma-0 pa-10 pt-0" step="7">
                            <p>
                                Herzlichen Glückwunsch! Du bist jetzt im Internet präsent und damit für deine potenziellen Neukund:innen sichtbar. 
                            </p>
                            <v-btn
                                color="primary"
                                @click="websitesteps = 1; $vuetify.goTo('#schritt1', options = {offset: 50})"
                            >
                                Zurück zum Anfang
                            </v-btn>
                        </v-stepper-content>
                    </v-stepper>
                </v-col>
                <v-col cols="12">
                    <v-card class="patterned" color="indigo darken-1" elevation="0" tile>
                        <v-card-text class="text-center white--text pa-10">
                            <h3 class="my-5" :class="$vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'">
                                Wie lange dauert es, bis die WordPress-Seite online gehen kann?
                            </h3>
                            <p class="text--large">
                                Die Dauer des Projekts hängt stark von der Komplexität und den Inhalten deiner Website ab.
                                 Eine einfache Website, für die sämtliche Inhalte bereits vorliegen, kann schon innerhalb von 3-4 Wochen online gestellt werden.
                                  Falls Texte, Fotos und dergleichen erst noch erstellt werden müssen, verlängert sich die Dauer entsprechend.
                            </p>
                            <h4  :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'">
                                Basierend auf meinen Erfahrungen dauert es im Durchschnitt etwa 2 Monate, bis du mit deiner WordPress-Seite online durchstarten kannst.
                            </h4>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    
                </v-col>
                <v-col cols="12" sm="6">
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>
import InfoCardVertical from "../components/InfoCardVertical.vue";

export default {
    name: "WebdesignWordpress",
    components: {
        InfoCardVertical
    },
    data: () => ({
        websitesteps: 1,
        references:  [
            {
                title: 'AE Projektmanagement',
                text: `
                    Für AE Projektmanagement war eine minimalistische Webseite gefragt. Mit nur zwei Farben, zwei Schriften und einfachen Linien und Rahmen entstand eine übersichtliche und schnelle Webseite.
                    Durch Verzicht auf unnötige Funktionen ist die Webseite von AE Projektmanagement auch sehr datensparsam und frei von Cookies oder anderen Tracking-Methoden.
                `,
                link: 'https://ae-projektmanagement.com/',
                image: 'images/references/reference-ae-projektmanagement.webp'
            },
            {
                title: 'Biohof Nofels',
                text: `
                    Auch der traditionelle Bio-Gemüsebauernhof muss heute Online auffindbar sein, aber zu umständlich soll das nicht sein.
                    Deshalb gibt es auch hier ein kleine aber feine Single-Page-Webseite mit den wichtigsten Daten.
                    So daß man das beste Gemüse in Vorarlberg (der einzige demeter-Betrieb für Gemüse) auf findet.
                `,
                link: 'https://biohof-nofels.at/',
                image: 'images/references/reference-biohof-nofels.webp'
            },
            {
                title: 'Bio-Restaurant Moritz',
                text: '\
                    Die Webseite des Bio-Restaurant Moritz hat ein einzigartiges Design von Gestaltungssache implementiert in Wordpress.\
                    Es ist problemlos möglich individuelle Designs mit der Funktionalität eines CMS zu kombinieren, inkl. Blog & Formular.\
                ',
                link: 'https://biomoritz.at/',
                image: 'images/references/reference-biomoritz.jpg'
            },
            {
                title: 'Digisam - Digital Signage',
                text: `
                    Die Digisam-Webseite bestand bereits mit einem modernem Design und tollen Inhalten.
                     Allerdings war das zugrundeliegende CMS schwer zu bedienen und unflexibel.
                      Deshalb wurde die Webseite mit Wordpress neu erstellt, Design und Inhalte übertragen und die Kunden so eingeschult,
                       daß sie ihre Webseite selbst bedienen können.
                `,
                link: 'https://digisam.at/',
                image: 'images/references/reference-digisam.webp'
            },
            {
                title: 'JUKA\'s - Feines aus Vorarlberg',
                text: `
                    Der Feinkostladen JUKA's mit Spezialitäten aus Vorarlberg brauchte eine Webseite die ihre köstlichen Produkte gut zur Geltung bringt.
                     Mit einem Design von der teamwork werbeagentur ist das garkein Problem.
                      Die Gestaltung zieht sich vom Laden, über die Drucksachen bis hin zur Webseite durch.
                       So bekommt JUKA's einen hohen Wiedererkennungswert.
                `,
                link: 'https://jukas.at/',
                image: 'images/references/reference-jukas.webp'
            },
            {
                title: 'Scenography & Costumedesign by Lisa',
                text: `
                    Wenn Zeit oder Geld fehlen um eine umfangreiche Website zu erstellen, bieten sich Single Page Websites als professionelle Lösung an.
                     So werden die wichtigsten Informationen ohne unnötigen Aufwand ansprechend präsentiert.
                      Für Scenography & Costumedesign by Lisa, kann die Kundin selbst neue Projekte hinzufügen und ihre Referenzen auf dem neuesten Stand halten.
                       Gleichzeitig muss sie aber keine umfangreiche Webseite verwalten, nur genau das was notwendig ist.
                `,
                link: 'https://scenography-and-costumedesign-by-lisa.de/',
                image: 'images/references/reference-scenes-and-costumes.jpg'
            },
            {
                title: 'V-Consulting - Die Beratungsmanufaktur',
                text: `
                    Die Unternehmensberatung V-Cosnulting wollte ihren Webauftritt modernisieren.
                     Auch hier gab es einen Wechsel von einem Online-Baukasten auf Wordpress mit Avada.
                      Das Design besticht mit großen Weißräumen, professionellen Fotos und individuellen Icons (tolle Arbeit, Sophie).
                       Die Inhalte können vom Kunden aktualisiert werden, so das Seminaredaten und Blog-Beiträge immer auf dem neuesten Stand sind.
                `,
                link: 'https://v-consulting.at/',
                image: 'images/references/reference-vconsulting.webp'
            },
            {
                title: 'Zapfig Living',
                text: '\
                    Die bestehende Webseite von Zapfig Living wurde auf Wordpress migriert und das Design von Winderwild übernommen.\
                    Außerdem wurde die Buchungssoftware von Zimmersoftware direkt eingebunden und optisch angepasst.\
                ',
                link: 'https://zapfig-living.at/',
                image: 'images/references/reference-zapfigliving.webp'
            },
        ]
    })
};
</script>
